import React, {useContext} from 'react';
import {UploadOutlined} from '@ant-design/icons';
import {App, Button, Col, message, Row, Upload, UploadProps} from 'antd';
import {AxiosProgressEvent, AxiosRequestConfig} from 'axios';
import {AuthContext} from "../../services/auth-context";
import {RcFile} from "antd/es/upload";
import {instance} from "../../services/axios-config";
import {BaseContent} from "../../components/base-content";
import Title from "antd/es/typography/Title";
import {WorkingSystemConfigContext} from "../../services/working-system-config-provider";

const FileUpload: React.FC = () => {
  const authContext = useContext(AuthContext);
  const wscContext = useContext(WorkingSystemConfigContext);

  const {notification} = App.useApp();

  const buildProps = (path: string) : UploadProps => {
    return {
      name: 'file',
      multiple: true,
      action: '',
      itemRender: () => (<div></div>),
      beforeUpload: (file: RcFile) => {
        const isFileType = file.type === 'text/csv';
        console.log(file.type)
        if (!isFileType) {
          message.error('You can only upload csv files!');
        }
        return isFileType;
      },
      customRequest: async (options: any) => {
        const data = new FormData();
        data.append(options.filename || '', options.file);
        const config: AxiosRequestConfig = {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authContext.token}`,
            quarter: wscContext.value.quarter,
          },
          onUploadProgress: (event: AxiosProgressEvent) => {
            if (event.total) {
              options.onProgress({percent: Math.round((event.loaded / event.total) * 100)}, options.file);
            }
          },
        };
        try {
          const response = await instance.post(path, data, config);
          options.onSuccess(response.data, options.file);
        } catch (err) {
          console.log('Error: ', err);
          options.onError(err);
        }
      },
      onChange(info) {
        const {status} = info.file;
        if (status === 'done') {
          notification.success({
            message: `${info.file.name} salvo com sucesso.`,
            duration: 2,
          });
        } else if (status === 'error') {
          notification.error({
            message: `Não foi possivel ler o documento ${info.file.name}.`,
            duration: 5,
          });
        }
      },
    };
  };

  return (
    <BaseContent>
      <Row>
        <Col span={3}>
          <Title level={4}>Estoque</Title>
          <Upload {...buildProps("/v1/sample_stock/import_base")}>
            <Button icon={<UploadOutlined/>}>Base</Button>
          </Upload>
          <p></p>
          <Upload {...buildProps("/v1/file/import_stock_docs")}>
            <Button icon={<UploadOutlined/>}>Documento</Button>
          </Upload>
          <p></p>
          <Upload {...buildProps("/v1/appointment_stock/import")}>
            <Button icon={<UploadOutlined/>}>Auditoria</Button>
          </Upload>
        </Col>
        <Col span={3}>
          <Title level={4}>Carteira</Title>
          <Upload {...buildProps("/v1/sample_wallet/import_base")}>
            <Button icon={<UploadOutlined/>}>Base</Button>
          </Upload>
          <p></p>
          <Upload {...buildProps("/v1/file/import_wallet_docs")}>
            <Button icon={<UploadOutlined/>}>Documento</Button>
          </Upload>
          <p></p>
          <Upload {...buildProps("/v1/appointment_wallet/import")}>
            <Button icon={<UploadOutlined/>}>Auditoria</Button>
          </Upload>
        </Col>
      </Row>
    </BaseContent>
  );
};

export default FileUpload;