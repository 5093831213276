import {instance} from "../services/axios-config";
import {useQuery} from "react-query";

export interface DynamicTextField {
  textType: number
  textLabel: string
}

const load = async (params: any) => {
  if (!params.idReceivableType || !params.idDocumentType) return null;

  const response = await instance.get(
    `/v1/param/list_text_fields`,
    {
      params: {
        idReceivableType: params.idReceivableType,
        idDocumentType: params.idDocumentType,
      }
    }
  );

  return (response.data as Array<any>).map<DynamicTextField>((it) => ({textType: it.textType, textLabel: it.textLabel}));
}

export function DoLoadDynamicInputFields(idReceivableType: number, idDocumentType: number) {
  return useQuery<DynamicTextField[] | null>(
    `/v1/param/list_text_fields`,
    () => load({idReceivableType, idDocumentType}),
    {
      staleTime: 60 * 1000,
    }
  );
}