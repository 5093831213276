import {instance} from "../../../services/axios-config";
import User from "../../../entities/user";
import {useQuery} from "react-query";

const loadBase = async () => {
  const response = await instance.get(`/v1/user/list`);
  return  response.data.map((it: any) => User.fromJson(it));
}

export function DoLoadUsers() {
  return useQuery<Array<User>>(
    `/v1/user/list`,
    () => loadBase(),
    {
      staleTime: 60 * 1000,
    }
  );
}