import React from 'react';
import {BaseContent} from "../../components/base-content";
import Title from "antd/es/typography/Title";
import {Card, Col, Row} from "antd";
import Meta from "antd/es/card/Meta";
import {useNavigate} from "react-router-dom";

export default function DashboardPage() {
  const navigate = useNavigate();

  return (
    <BaseContent>
      <Title level={4}>Auditoria</Title>
      <Row gutter={16}>
        <Col span={4}>
          <Card
            hoverable
            onClick={() => navigate("/archive-stock-page")}
            style={{textAlign: 'center'}}
          >
            <Meta title='Estoque'/>
          </Card>
        </Col>
        <Col span={4}>
          <Card
            hoverable
            onClick={() => navigate("/archive-wallet-page")}
            style={{textAlign: 'center'}}
          >
            <Meta title='Carteira'/>
          </Card>
        </Col>
      </Row>
    </BaseContent>
  );
}