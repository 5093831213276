import {createContext, useEffect, useReducer} from 'react';

export const UserContext: React.Context<any> = createContext({});

export const UserContextProvider = (props: any) => {
  const [user, setUser] = useReducer((_: any, user: any) => user, [], () => {
    return JSON.parse(localStorage.getItem("user") ?? '{}');
  });

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user));
  }, [user])

  return (
    <UserContext.Provider value={{user, setUser}}>
      {props.children}
    </UserContext.Provider>
  )
}