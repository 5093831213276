import {useQuery} from "react-query";
import {instance} from "../services/axios-config";
import ReceivableType from "../entities/receivable-type";

async function doLoadReceivableTypes(): Promise<Array<ReceivableType>> {
  const response = await instance.get('/v1/param/receivable_type/list');
  return response.data.map((it: any) => ReceivableType.fromMap(it));
}

export function DoLoadReceiveTypes() {
  return useQuery<Array<ReceivableType>>(
    '/v1/receivable_type/list',
    () => doLoadReceivableTypes(),
    {
      staleTime: 60 * 1000,
    }
  );
}