import {Button, List, Skeleton} from "antd";
import React, {useState} from "react";
import UserFormModal from "./components/user-form-modal";
import {BaseContent} from "../../components/base-content";
import {DoLoadUsers} from "./hooks/do-load-users";
import User from "../../entities/user";
import {DoChangeUserStatus} from "./hooks/do-change-user-status";
import {ConfirmModal} from "../../components/confirm-modal";

export default function UserManagementPage() {
  const [formVisible, setFormVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const userQuery = DoLoadUsers();
  const deleteUserQuery = DoChangeUserStatus();
  const [user, setUser] = useState<User>();

  function onEditTap(u: User) {
    setUser(u);
    setFormVisible(true);
  }

  function onChangeStatus(u: User) {
    setUser(u);
    setDeleteModalVisible(true);
  }

  async function changeStatusSubmit() {
    if (!user) return;

    setDeleteModalVisible(false);
    await deleteUserQuery.mutateAsync(user);
    setUser(undefined);
    userQuery.refetch();
  }

  return (
    <BaseContent>
      {
        userQuery.isLoading ||
        deleteUserQuery.isLoading
          ? <Skeleton/>
          : (<List
            pagination={{position: 'bottom', align: 'center'}}
            header={<Button type="primary" onClick={() => setFormVisible(true)}>Novo Usuário</Button>}
            dataSource={userQuery.data}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <a onClick={() => onEditTap(item)}>Editar</a>,
                  <a onClick={() => onChangeStatus(item)}>{item.dataStatus === 1 ? 'Desativar' : 'Ativar'}</a>
                ]}
              >
                <List.Item.Meta
                  title={`${item.name} ${item?.dataStatus !== 1 ? '(Inativo)' : ''}`}
                />
              </List.Item>
            )}
          />)
      }
      <UserFormModal
        formVisible={formVisible}
        user={user}
        onClose={async () => {
          setFormVisible(false);
          setUser(undefined);
          userQuery.refetch();
        }}
      />
      <ConfirmModal
        title={user?.dataStatus === 1 ? 'Inativar usuário?' : 'Ativar usuário?'}
        isVisible={deleteModalVisible}
        onCancel={() => setDeleteModalVisible(false)}
        onOk={() => changeStatusSubmit()}
      />
    </BaseContent>
  );
}