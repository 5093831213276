import {createContext, useEffect, useReducer} from "react";

export const WorkingSystemConfigContext: React.Context<any> = createContext({});


export function WorkingSystemConfigContextProvider(props: any) {
  const [value, setValue] = useReducer((_: any, it: any) => it, [], () => {
    return JSON.parse(localStorage.getItem("working-system-config") ?? '{}');
  });

  useEffect(() => {
    localStorage.setItem('working-system-config', JSON.stringify(value));
  }, [value]);

  return (
    <WorkingSystemConfigContext.Provider value={{value, setValue}}>
      {props.children}
    </WorkingSystemConfigContext.Provider>
  )
}