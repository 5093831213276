import {App} from "antd";
import {instance} from "../../../services/axios-config";
import {useMutation} from "react-query";
import {AxiosError} from "axios";
import SampleWallet from "../../../entities/sample-wallet";

export interface ChangeReceivableType {
  sampleId: number;
  receivableTypeId: number;
}

const saveReceivableType = async (item: ChangeReceivableType) => {
  const response = await instance.post('/v1/sample_wallet/change_receivable_type', {
    'sample_id': item.sampleId,
    'receivable_type_id': item.receivableTypeId,
  });

  if (response.status !== 200) return null;
  return SampleWallet.fromJson(response.data);
}

export function DoChangeWalletReceivableType() {
  const {notification} = App.useApp();
  const {mutate, mutateAsync, isLoading, isError} = useMutation(saveReceivableType, {
      onError: (error: AxiosError) => {
        const message = (error.response?.data as any)?.message ?? 'Erro interno';

        notification.error({
          duration: 7,
          message: message,
        });
      },
    }
  );

  return {mutate, mutateAsync, isLoading, isError};
}