import {LoadingOutlined} from "@ant-design/icons";
import {Result} from "antd";
import React, {useEffect} from "react";
import PropTypes from "prop-types";

const CustomResultComponent = (props: any) => {
  useEffect(() => {
    if (!props.isLoading) {
      setTimeout(() => props.doRedirect(), 600);
    }
  }, [props.isLoading]);

  const buildComponent = () => {
    if (props.isLoading) {
      return (
        <Result
          icon={<LoadingOutlined/>}
          title="Salvando auditoria!"
        />
      );
    }

    return (
      <Result
        status="success"
        title="Auditoria concluído!"
      />
    );
  }

  return buildComponent();
}

CustomResultComponent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  doRedirect: PropTypes.func.isRequired,
};

export default CustomResultComponent;