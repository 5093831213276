import {instance} from "./axios-config";
import {useNavigate} from "react-router-dom";
import {useContext, useEffect, useRef} from "react";
import {AuthContext} from "./auth-context";
import {App} from "antd";
import {UserContext} from "./user-context";
import PageGuard from "../routes/page-guard";

export function AxiosInterceptor({children}: any) {
  const navigate = useNavigate();
  const {setToken} = useContext(AuthContext);
  const {setUser} = useContext(UserContext);
  const {notification} = App.useApp();
  const initialized = useRef(false);

  useEffect(() => {
    if (initialized.current) return;

    instance.interceptors.response.use(
      function (response) {
        return response;
      },
      error => {
        if (error.response.status === 401 && !window.location.pathname.includes('/login')) {
          notification.error({
            message: 'Sua conexão expirou',
            duration: 5,
          })

          setUser('')
          setToken('')
          navigate('/login', {
            replace: true,
            state: {
              commingFromRedirect: true
            }
          });
        }

        return Promise.reject(error);
      });

    initialized.current = true;
  }, []);

  PageGuard();
  return children;
}