import React, {useContext} from "react";
import {Route, Routes} from "react-router-dom";
import LoginPage from "../pages/login/login-page";
import RouteOptions from "./routes";
import {UserContext} from "../services/user-context";

export default function PageRoutes() {
  const {user} = useContext(UserContext);
  const routeOptions = RouteOptions.filter(it => it.allow.includes(user.role));

  let routes = routeOptions.map((it) => {
    return <Route path={it.path} key={it.path} Component={it.component}/>
  });

  routeOptions
    .forEach((x) => (x.nestedRoutes ?? [])
      .forEach((it) => {
        routes.push(<Route Component={it.component} path={it.path} key={it.path}/>)
      }))

  return (
    <Routes>
      <Route Component={LoginPage} path="/login" key="/login"/>
      <Route>
        {routes}
      </Route>
    </Routes>
  )
}