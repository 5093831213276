export class DivergenceType {
  id: number;
  code: string;
  label?: string;
  createdAt: Date;
  changedAt: Date;
  dataStatus: number;
  documentTypeIds: number[];

  constructor(id: number, code: string, label: string, createdAt: Date, changedAt: Date, dataStatus: number, documentTypeIds: number[]) {
    this.id = id;
    this.code = code;
    this.label = label;
    this.createdAt = createdAt;
    this.changedAt = changedAt;
    this.dataStatus = dataStatus;
    this.documentTypeIds = documentTypeIds;
  }

  static fromJson(
    {
      id,
      code,
      label,
      createdAt,
      changedAt,
      dataStatus,
      documentTypeIds
    }: any
  ): DivergenceType {
    return new DivergenceType(
      id,
      code,
      label,
      createdAt,
      changedAt,
      dataStatus,
      documentTypeIds,
    );
  }
}