import React from "react";
import {DownloadOutlined, HomeOutlined, ReconciliationOutlined, UploadOutlined, UserOutlined} from "@ant-design/icons";
import UserManagementPage from "../pages/user-management/user-management-page";
import ImportPage from "../pages/import/import-page";
import ArchiveStockPage from "../pages/archive-stock/archive-stock-page";
import {ArchiveAppointmentStockPage} from "../pages/archive-appointment-stock/archive-appointment-stock-page";
import DashboardPage from "../pages/dashboard/dashboard-page";
import ArchiveWalletPage from "../pages/archive-wallet/archive-wallet-page";
import {ArchiveAppointmentWalletPage} from "../pages/archive-appointment-wallet/archive-appointment-wallet-page";
import {ReportPage} from "../pages/report/report-page";

const RouteOptions = [
    {
        key: '1',
        icon: React.createElement(HomeOutlined),
        label: 'Dashboard',
        path: '/',
        component: DashboardPage,
        allow: ['admin', 'user'],
        nestedRoutes: [
            {
                icon: React.createElement(ReconciliationOutlined),
                label: 'Arquivos',
                path: '/archive-stock-page/item/:id',
                component: ArchiveAppointmentStockPage,
                allow: ['admin', 'user'],
            },
            {
                icon: React.createElement(ReconciliationOutlined),
                label: 'Arquivos',
                path: '/archive-stock-page',
                component: ArchiveStockPage,
                allow: ['admin', 'user'],
            },
            {
                icon: React.createElement(ReconciliationOutlined),
                label: 'Arquivos',
                path: '/archive-wallet-page/item/:id',
                component: ArchiveAppointmentWalletPage,
                allow: ['admin', 'user'],
            },
            {
                icon: React.createElement(ReconciliationOutlined),
                label: 'Arquivos',
                path: '/archive-wallet-page',
                component: ArchiveWalletPage,
                allow: ['admin', 'user'],
            }
        ]
    },
    {
        key: '2',
        icon: React.createElement(UserOutlined),
        label: 'Gerenciar usuários',
        path: '/user-management',
        component: UserManagementPage,
        allow: ['admin'],
    },
    {
        key: '3',
        icon: React.createElement(UploadOutlined),
        label: 'Importação',
        path: '/import-page',
        component: ImportPage,
        allow: ['admin'],
    },
    {
        key: '4',
        icon: React.createElement(DownloadOutlined),
        label: 'Relatórios',
        path: '/report',
        component: ReportPage,
        allow: ['admin', 'user'],
    },
    // {
    //     key: '5',
    //     icon: React.createElement(ContainerOutlined),
    //     label: 'Parametrização',
    //     path: '/parametrization-page',
    //     component: ParametrizationPage
    // },
];

export default RouteOptions;