import {AppointmentSituation} from "./appointment-situation";
import {DynamicField} from "./dynamic-field";

export class AppointmentStock {
  id?: number;
  sampleId?: number;
  divergenceTypeIds?: number[];
  documentTypeId?: number;
  assignorName?: string;
  assignorId?: string;
  draweeName?: string;
  draweeId?: string;
  yourNumber?: string;
  documentNumber?: string;
  nominalValue?: string;
  totalValue?: string;
  comment?: string;
  dueDate?: Date;
  situation?: AppointmentSituation;
  customFields?: DynamicField[];

  static CopyFrom(b: AppointmentStock): AppointmentStock {
    const a = new AppointmentStock();

    a.id = b.id;
    a.sampleId = b.sampleId;
    a.documentTypeId = b.documentTypeId;
    a.assignorName = b.assignorName;
    a.assignorId = b.assignorId;
    a.draweeName = b.draweeName;
    a.draweeId = b.draweeId;
    a.yourNumber = b.yourNumber;
    a.documentNumber = b.documentNumber;
    a.nominalValue = b.nominalValue;
    a.dueDate = b.dueDate;
    a.divergenceTypeIds = b.divergenceTypeIds;
    a.situation = b.situation;
    a.comment = b.comment;
    a.totalValue = b.totalValue;
    a.customFields = b.customFields;

    return a;
  }

  static fromJson(
    {
      id,
      sampleId,
      divergenceTypeIds,
      documentTypeId,
      assignorName,
      assignorId,
      draweeName,
      draweeId,
      yourNumber,
      documentNumber,
      nominalValue,
      totalValue,
      comment,
      dueDate,
      situation,
      customFields,
    }: any): AppointmentStock {
    const a = new AppointmentStock();

    a.id = id;
    a.sampleId = sampleId;
    a.divergenceTypeIds = divergenceTypeIds;
    a.documentTypeId = documentTypeId;
    a.assignorName = assignorName;
    a.assignorId = assignorId;
    a.draweeName = draweeName;
    a.draweeId = draweeId;
    a.yourNumber = yourNumber;
    a.documentNumber = documentNumber;
    a.nominalValue = nominalValue;
    a.totalValue = totalValue;
    a.comment = comment;
    a.dueDate = dueDate;
    a.situation = situation;
    a.customFields = customFields;
    return a;
  }
}