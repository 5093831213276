import DescriptionsItem from "antd/es/descriptions/Item";
import {Badge, Button, Collapse, DatePicker, Descriptions, Form, Input, Popover, Row, Select, Space} from "antd";
import {CloseOutlined, EditTwoTone} from "@ant-design/icons";
import React, {useEffect, useReducer, useState} from "react";
import CustomCurrencyInput from "../../../components/custom-currency-input";
import 'antd/dist/reset.css'
import {useForm} from "antd/es/form/Form";
import {formatDate} from "../../../services/date-format";
import {formatCurrency} from "../../../services/number-fomat";
import {useLocation, useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import SampleWallet from "../../../entities/sample-wallet";
import {AppointmentWallet} from "../../../entities/appointment-wallet";
import ReceivableType from "../../../entities/receivable-type";
import {DoChangeWalletReceivableType} from "../hooks/do-change-wallet-receivable-type";

interface DescriptionItem {
  key: string;
  label: string;
  value: any;
  span?: number;
  updated?: boolean;
  input?: DescriptionInput[];
}

interface DescriptionInput {
  key: string;
  input: any;
  type: string;
  options?: Array<Option>;
}

interface Option {
  label: string
  value: string
}

export function DescriptionComponent({appointment, onSaveInput, isBlock, clearAppointment}: {
  appointment: AppointmentWallet,
  onSaveInput: (v: any) => void,
  isBlock: boolean,
  clearAppointment: (v: string[]) => void,
}) {
  const location = useLocation();
  const navigate = useNavigate();

  const sample: SampleWallet = location.state.sample;
  const receivableType = location.state.receivableType;
  const receivableTypes = location.state.receivableTypes;
  const [form] = useForm();
  const [open, setOpen] = useState<any>(undefined);
  const doChangeReceivableType = DoChangeWalletReceivableType();

  const handleOpenPopOver = (di: DescriptionItem, b: boolean) => {
    di.input?.forEach(it => {
      form.setFieldValue(it.key, it.input);
    })
    setOpen(b ? di.key : undefined);
  };
  const verifyOpenPopOver = (key: string) => open === key;
  const buildSampleData = () => [
    {
      key: '1',
      label: 'Fundo',
      value: sample.fund,
      span: 2,
    },
    {
      key: '2',
      label: 'Critério',
      value: sample.criterion,
    },
    {
      key: '3',
      label: 'Tipo Recebível',
      value: receivableType.label ?? '',
      span: 2,
      input: [
        {
          key: 'receivableType',
          input: `${receivableType.id}`,
          type: 'select',
          options: receivableTypes
            ?.map((it: ReceivableType) => ({label: it.label, value: `${it.id}`})) ?? [],
        }
      ],
    },
    {
      key: '4',
      label: 'Data aplicação',
      value: formatDate(sample.applicationDate),
    },
    {
      key: '5',
      label: 'Emitente',
      value: sample.emitter,
      span: 2,
      updated: appointment.emitter !== undefined,
      input: [
        {
          key: 'emitter',
          input: appointment.emitter ?? sample.emitter,
          type: 'text'
        },
      ],
    },
    {
      key: '6',
      label: 'Número proposta',
      value: sample.proposal,
      updated: appointment.proposal !== undefined,
      input: [
        {
          key: 'proposal',
          input: appointment.proposal ?? sample.proposal,
          type: 'text'
        },
      ],
    },
    {
      key: '7',
      label: 'Quantidade',
      value: sample.quantity,
      updated: appointment.quantity !== undefined,
      span: 2,
      input: [
        {
          key: 'quantity',
          input: appointment.quantity ?? sample.quantity,
          type: 'text'
        },
      ],
    },
    {
      key: '8',
      label: 'Valor Aplicação',
      value: formatCurrency(Number(sample.applicationValue)),
      updated: appointment.applicationValue !== undefined,
      input: [
        {
          key: 'applicationValue',
          input: appointment.applicationValue ?? sample.applicationValue,
          type: 'decimal'
        },
      ],
    },
    {
      key: '9',
      label: 'Papel',
      value: sample.documentPaper,
      updated: appointment.documentPaper !== undefined,
      span: 2,
      input: [
        {
          key: 'documentPaper',
          input: appointment.documentPaper ?? sample.documentPaper,
          type: 'text'
        },
      ],
    },
    {
      key: '10',
      label: 'Código',
      value: sample.documentCode,
      updated: appointment.documentCode !== undefined,
      input: [
        {
          key: 'documentCode',
          input: appointment.documentCode ?? sample.documentCode,
          type: 'text'
        },
      ],
    },
    {
      key: '11',
      label: 'Valor bruto',
      value: formatCurrency(Number(sample.grossValue)),
      updated: appointment.grossValue !== undefined,
      input: [
        {
          key: 'grossValue',
          input: appointment.grossValue ?? sample.grossValue,
          type: 'decimal'
        },
      ],
    },
    {
      key: '12',
      label: 'Vencimento',
      value: formatDate(sample.dueDate),
      updated: appointment.dueDate !== undefined,
      input: [
        {
          key: 'dueDate',
          input: dayjs(appointment.dueDate ?? sample.dueDate),
          type: 'date'
        },
      ],
    },
  ];

  const [items, setItems] = useReducer((_: any, it: any) => it, [], () => {
    return buildSampleData();
  });

  const onFormSubmit = async (values: any) => {
    const map = new Map(Object.entries(values));

    if (map.has('receivableType')) {
      const response = await doChangeReceivableType.mutateAsync({
        sampleId: sample.id,
        receivableTypeId: parseInt(`${map.get('receivableType')}`)
      });

      if (response) {
        navigate('/archive-wallet-page', {
          replace: true,
          state: {
            sampleRedirect: response,
            receivableType: receivableTypes.find((it: ReceivableType) => it.id === response.receivableTypeId)
          }
        });
      }

      return;
    }

    onSaveInput(map);
    setOpen(undefined);
  }

  useEffect(() => {
    setItems(buildSampleData());
    form.resetFields();
  }, [appointment]);

  const buildInput = (di: DescriptionInput) => {
    switch (di.type) {
      case 'date':
        return (
          <Form.Item
            shouldUpdate={() => true}
            name={di.key}
            key={`fi-${di.key}`}
            initialValue={di.input}
            noStyle
          >
            <DatePicker
              format="DD/MM/YYYY"
              key={`in-${di.key}`}
            />
          </Form.Item>
        );
      case 'decimal':
        return <Form.Item
          shouldUpdate={() => true}
          initialValue={di.input}
          name={di.key}
          key={`fi-${di.key}`}
          noStyle
        >
          <CustomCurrencyInput key={`in-${di.key}`} style={{width: '100%'}}/>
        </Form.Item>
      case 'select':
        return <Form.Item
          shouldUpdate={() => true}
          initialValue={di.input}
          name={di.key}
          key={`fi-${di.key}`}
          noStyle
        >
          <Select
            key={`in-${di.key}`}
            style={{width: '200px'}}
            value={di.input}
            popupMatchSelectWidth={false}
            options={di.options}
          />
        </Form.Item>
      default:
        return <Form.Item
          shouldUpdate={() => true}
          initialValue={di.input}
          name={di.key}
          key={`fi-${di.key}`}
          noStyle
        >
          <Input key={`in-${di.key}`}/>
        </Form.Item>
    }
  }

  const onClearAppointment = (input?: DescriptionInput[]) => {
    const items = input?.map(it => it.key) ?? [];
    clearAppointment(items);
  }

  const buildedItems = () => items.map((it: DescriptionItem, index: number) => {
    return (
      <DescriptionsItem
        label={it.updated ? <Badge color='red' text={it.label}/> : it.label}
        span={it.span}
        key={`di-${index.toString()}`}
      >
        <Row justify='space-between'>
          {it.value}
          {it.input
            &&
            <div>
              {
                it.updated
                &&
                <CloseOutlined style={{cursor: "pointer", marginRight: '5px'}} onClick={() => onClearAppointment(it.input)}/>
              }
              <Popover
                key={`pop-over-${index}`}
                content={(
                  <Form
                    disabled={isBlock}
                    form={form}
                    onFinish={onFormSubmit}
                  >
                    <Space.Compact>
                      {it.input!.map(buildInput)}
                      <Form.Item noStyle>
                        <Button
                          type='primary'
                          htmlType='submit'
                        >Ok</Button>
                      </Form.Item>
                    </Space.Compact>
                  </Form>
                )}
                title={it.label}
                trigger="click"
                open={verifyOpenPopOver(it.key)}
                onOpenChange={(b) => handleOpenPopOver(it, b)}
              >
                <EditTwoTone style={{cursor: "pointer"}}/>
              </Popover>
            </div>
          }
        </Row>
      </DescriptionsItem>
    )
  })

  return (
    <div>
      <Input hidden={true}/>
      <Collapse
        collapsible='header'
        bordered={false}
        defaultActiveKey={['1']}
        items={[
          {
            key: '1',
            label: 'Base',
            children: [
              <Descriptions
                layout="vertical"
                bordered
                size='small'
              >
                {buildedItems()}
              </Descriptions>
            ]
          }
        ]}
      />
    </div>
  )
}