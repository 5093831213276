import z3 from '../../assets/images/background.svg'
import z1 from '../../assets/images/logo-branco.svg'
import {Button, Card, Col, Form, Input, Layout, Row} from "antd";
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {Content} from "antd/es/layout/layout";
import {UseLogin} from "../../hooks/login";
import SystemConfig from "../../components/quarter/system-config-modal";
import React, {useContext, useEffect, useState} from "react";
import {type} from "node:os";
import {AuthContext} from "../../services/auth-context";
import {UserContext} from "../../services/user-context";
import {useNavigate} from "react-router-dom";
import SystemConfigModal from "../../components/quarter/system-config-modal";

export default function LoginPage() {
  const {mutate, isLoading, data} = UseLogin();
  const [configStatus, setConfigStatus] = useState(false);
  const {setToken} = useContext(AuthContext);
  const {setUser} = useContext(UserContext);
  const navigation = useNavigate();
  const [response, setResponse] = useState<any>();

  async function onFinish(values: any) {
    mutate(values);
  }

  useEffect(() => {
    if (typeof data !== "undefined") {
      setResponse(data);
      setConfigStatus(true);
    }
  }, [data]);

  const onSystemConfigComplete = () => {
    setToken(response.token);
    setUser(response);
    navigation('/', {replace: true})
  }

  return (
    <Layout style={{minHeight: '100vh', backgroundImage: `url(${z3})`, backgroundSize: 'auto'}}>
      <Content>
        <Row align="middle" justify="center" style={{minHeight: '100vh'}}>
          <Col>
            <img src={z1} width={600} alt="Logo"/>
            <p></p>
            <Card style={{width: 300, margin: 'auto'}}>
              <Form
                name="login"
                className="login-form"
                initialValues={{remember: false}}
                onFinish={onFinish}
                autoComplete={"off"}
              >
                <Form.Item
                  name="username"
                  rules={[{required: true, message: 'Please input your Username!'}]}
                >
                  <Input prefix={<UserOutlined className="site-form-item-icon"/>}
                         placeholder="Username"/>
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[{required: true, message: 'Please input your Password!'}]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon"/>}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    loading={isLoading}
                    type="primary"
                    htmlType="submit"
                    className="login-form-button">
                    Conectar
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
      <SystemConfigModal
          callback={onSystemConfigComplete}
          isVisible={configStatus}
      />
    </Layout>
  )
}