import {useQuery} from "react-query";
import {instance} from "../services/axios-config";
import {DocumentType} from "../entities/document-type";


const listDocumentType = async (receivableTypeId: number) => {
  const response = await instance.get(`/v1/param/receivable_type/${receivableTypeId}/documents`);
  return response.data.map(DocumentType.fromJson);
}

export function DoLoadDocumentTypeList(receivableTypeId: number) {
  return useQuery<Array<DocumentType>>(
    `/v1/param/receivable_type/${receivableTypeId}/documents`,
    () => listDocumentType(receivableTypeId),
    {
      staleTime: 60 * 1000,
    }
  );
}