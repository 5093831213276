export default class ReceivableType {
  id: number;
  code: number;
  label: number;
  dataStatus: number;
  createdAt: Date;
  changedAt: Date;


  constructor(
    id: number,
    code: number,
    label: number,
    dataStatus: number,
    createdAt: Date,
    changedAt: Date
  ) {
    this.id = id;
    this.code = code;
    this.label = label;
    this.dataStatus = dataStatus;
    this.createdAt = createdAt;
    this.changedAt = changedAt;
  }

  static fromMap({id, code, label, dataStatus, createdAt, changedAt}: any
  ): ReceivableType {
    return new ReceivableType(
      id,
      code,
      label,
      dataStatus,
      createdAt,
      changedAt,
    );
  }
}