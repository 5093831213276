import {useEffect, useReducer} from "react";

const filterSampleKey = 'filter-sample-stock-key'

export const useSampleStockFilterService = () => {
  const [value, setValue] = useReducer((_: any, v: any) => v, [], () => {
    const value = localStorage.getItem(filterSampleKey);
    if (value) return JSON.parse(value);

    return {};
  });

  useEffect(() => {
    localStorage.setItem(filterSampleKey, JSON.stringify(value));
  }, [value])

  return {value, setValue}
}