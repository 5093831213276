import {AppointmentSituation} from "./appointment-situation";
import {DynamicField} from "./dynamic-field";

export class AppointmentWallet {
  id?: number;
  sampleId?: number;
  divergenceTypeIds?: number[];
  documentTypeId?: number;
  documentCode?: string;
  documentPaper?: string;
  emitter?: string;
  proposal?: string;
  quantity?: string;
  grossValue?: string;
  applicationValue?: string;
  dueDate?: Date;
  applicationDate?: Date;
  comment?: string;
  situation?: AppointmentSituation;
  customFields?: DynamicField[];

  static CopyFrom(b: AppointmentWallet): AppointmentWallet {
    const a = new AppointmentWallet();

    a.id = b.id;
    a.sampleId = b.sampleId;
    a.divergenceTypeIds = b.divergenceTypeIds;
    a.documentTypeId = b.documentTypeId;
    a.documentCode = b.documentCode;
    a.documentPaper = b.documentPaper;
    a.emitter = b.emitter;
    a.proposal = b.proposal;
    a.quantity = b.quantity;
    a.grossValue = b.grossValue;
    a.applicationValue = b.applicationValue;
    a.dueDate = b.dueDate;
    a.applicationDate = b.applicationDate;
    a.comment = b.comment;
    a.situation = b.situation;
    a.customFields = b.customFields;

    return a;
  }

  static fromJson(
    {
      id,
      sampleId,
      divergenceTypeIds,
      documentTypeId,
      documentCode,
      documentPaper,
      emitter,
      proposal,
      quantity,
      grossValue,
      applicationValue,
      dueDate,
      applicationDate,
      comment,
      situation,
      customFields,
    }: any): AppointmentWallet {
    const a = new AppointmentWallet();

    a.id = id;
    a.sampleId = sampleId;
    a.divergenceTypeIds = divergenceTypeIds;
    a.documentTypeId = documentTypeId;
    a.documentCode = documentCode;
    a.documentPaper = documentPaper;
    a.emitter = emitter;
    a.proposal = proposal;
    a.quantity = quantity;
    a.grossValue = grossValue;
    a.applicationValue = applicationValue;
    a.dueDate = dueDate;
    a.applicationDate = applicationDate;
    a.comment = comment;
    a.situation = situation;
    a.customFields = customFields;
    return a;
  }
}