import {instance} from "../../../services/axios-config";
import {useQuery} from "react-query";
import {AppointmentStock} from "../../../entities/appointment-stock";

interface AppointmentParams {
  sampleId?: number
  documentTypeId?: number
}

const loadAppointment = async (params: AppointmentParams) => {
  if (!params.sampleId || !params.documentTypeId) return null;

  const response = await instance.post(`/v1/appointment_stock`, params);
  const item = AppointmentStock.fromJson(response.data);

  if (item.id === undefined) return null;

  return item;
}

export const DoLoadStockAppointment = (
  sampleId?: number,
  documentTypeId?: number
) => {
  return useQuery<AppointmentStock | null>(
    `/v1/appointment_stock/${sampleId}/${documentTypeId}`,
    () => loadAppointment({sampleId, documentTypeId}),
    {
      staleTime: 60 * 1000,
    }
  );
}