import React, {useEffect} from 'react'
import {Flex, Form, Input, Modal, Select} from "antd";
import Password from "antd/es/input/Password";
import User from "../../../entities/user";
import PropTypes from "prop-types";
import {DoCreateUser} from "../hooks/do-create-user";

const message = {message: 'Campo obrigatório!'};
const roles = [
  {
    value: 'admin',
    label: 'Administrador'
  },
  {
    value: 'user',
    label: 'Usuário'
  }
]


export default function UserFormModal(props: any) {
  const [form] = Form.useForm();
  const handleUserQuery = DoCreateUser();

  useEffect(() => {
    form.setFieldValue(['user', 'name'], props.user?.name);
    form.setFieldValue(['user', 'email'], props.user?.email);
    form.setFieldValue(['user', 'username'], props.user?.username);
    form.setFieldValue(['user', 'role'], props.user?.role);
  }, [props.user]);

  async function onSuccessfulSubmit({user}: any) {
    const it = new User(user.name, user.email, user.username, user.password, user.role);
    it.id = props.user?.id;
    await handleUserQuery.mutateAsync(it);

    form.resetFields();
    props.onClose();
  }

  function onCancelSubmit() {
    form.resetFields();
    props.onClose();
  }

  return (
    <Modal
      open={props.formVisible}
      destroyOnClose={true}
      centered={true}
      okText={'Confirmar'}
      onOk={form.submit}
      onCancel={onCancelSubmit}
      closable={false}
      maskClosable={false}
    >
      <Flex flex="fit-content">
        <Form
          form={form}
          labelCol={{span: 6}}
          wrapperCol={{span: 16}}
          style={{width: '100%'}}
          onFinish={onSuccessfulSubmit}
        >
          <Form.Item name={['user', 'name']} label="Nome" rules={[{required: true, ...message}]}>
            <Input/>
          </Form.Item>
          <Form.Item name={['user', 'email']} label="Email"
                     rules={[{type: 'email', required: true, ...message}]}>
            <Input/>
          </Form.Item>
          <Form.Item name={['user', 'username']} label="Usuário"
                     rules={[{type: 'string', required: true, ...message}]}>
            <Input/>
          </Form.Item>
          <Form.Item name={['user', 'password']} label="Senha"
                     rules={[{type: 'string', required: props.user === undefined, ...message}]}>
            <Password/>
          </Form.Item>
          <Form.Item label="Tipo" name={['user', 'role']} rules={[{required: true, ...message}]}>
            <Select options={roles}/>
          </Form.Item>
        </Form>
      </Flex>
    </Modal>
  );
}

UserFormModal.propTypes = {
  formVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object,
}