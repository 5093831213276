import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {QueryClientProvider} from "react-query";
import {queryClient} from "./services/query-client";
import {BrowserRouter} from 'react-router-dom';
import {ConfigProvider} from "antd";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <React.StrictMode>
        <ConfigProvider theme={{
          token: {
            colorPrimary: '#5D252C',
            colorBgBase: '#F6F6F6',
          },
          components: {
            Menu: {
              darkItemBg: 'transparent'
            }
          }
        }}>
          <App/>
        </ConfigProvider>
      </React.StrictMode>
    </QueryClientProvider>
  </BrowserRouter>
);

reportWebVitals();
