import {DeleteOutlined, PaperClipOutlined} from '@ant-design/icons';

export function UploadListItem({title, onDelete, onPreview}: {
  title: string,
  onDelete: () => void,
  onPreview: () => void,
}) {
  return (
    <div className='ant-upload-list-item ant-upload-list-item-done'>
      <div className='ant-upload-icon'>
        <PaperClipOutlined/>
      </div>
      <span className='ant-upload-list-item-name' title={title}>
        <span style={{cursor: 'pointer'}} onClick={onPreview}>{title}</span>
      </span>
      <div className='ant-upload-list-item-actions'>
        <button
          type='button'
          onClick={onDelete}
          className='ant-btn css-dev-only-do-not-override-1rqnfsa ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-action'>
          <DeleteOutlined/></button>
      </div>
    </div>
  );
}