import {instance} from "../../../services/axios-config";
import {useQuery} from "react-query";
import {AppointmentWallet} from "../../../entities/appointment-wallet";

interface AppointmentParams {
  sampleId?: number
  documentTypeId?: number
}

const loadAppointment = async (params: AppointmentParams) => {
  if (!params.sampleId || !params.documentTypeId) return null;

  const response = await instance.post(`/v1/appointment_wallet`, params);
  const item = AppointmentWallet.fromJson(response.data);

  if (item.id === undefined) return null;

  return item;
}

export const DoLoadAppointment = (
  sampleId?: number,
  documentTypeId?: number
) => {
  return useQuery<AppointmentWallet | null>(
    `/v1/appointment_wallet/${sampleId}/${documentTypeId}`,
    () => loadAppointment({sampleId, documentTypeId}),
    {
      staleTime: 60 * 1000,
    }
  );
}