import React, {useContext, useEffect} from 'react'
import {Form, Modal, Select} from "antd";
import {WorkingSystemConfigContext} from "../../services/working-system-config-provider";
import PropTypes from "prop-types";

export default function SystemConfigModal({ callback, isVisible } : any) {
  const [form] = Form.useForm();
  const wscContext = useContext(WorkingSystemConfigContext);

  useEffect(() => {
    form.setFieldValue(['quarter'], wscContext.value.quarter);
  }, [wscContext.value]);

  const onSubmit = () => {
    wscContext.setValue({
      quarter: form.getFieldValue('quarter')
    });

    form.resetFields();
    callback();
  }

  return (
    <Modal
      open={isVisible}
      destroyOnClose={true}
      centered={true}
      okText={'Confirmar'}
      closable={false}
      onCancel={undefined}
      cancelButtonProps={{style: {display: 'none'}}}
      onOk={form.submit}
    >
      <Form
        form={form}
        labelCol={{span: 6}}
        wrapperCol={{span: 16}}
        style={{width: '100%'}}
        onFinish={onSubmit}
      >
        <h3>Configuração inicial</h3>
        <Form.Item name='quarter' required={true} label='Trimestre'>
          <Select style={{width: '100%'}} placeholder='Trimestre'>
            <option value="1">Primeiro</option>
            <option value="2">Segundo</option>
            <option value="3">Terceiro</option>
            <option value="4">Quarto</option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

SystemConfigModal.prototype = {
  callback: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
};