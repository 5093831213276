import {createContext, useEffect, useReducer} from "react";

export const WorkingDocumentTypeContext: React.Context<any> = createContext({});


export function WorkingDocumentTypeContextProvider(props: any) {
  const [value, setValue] = useReducer((_: any, it: any) => it, [], () => {
    return JSON.parse(localStorage.getItem("working-document-type") ?? '{}');
  });

  useEffect(() => {
    localStorage.setItem('working-document-type', JSON.stringify(value));
  }, [value]);

  return (
    <WorkingDocumentTypeContext.Provider value={{value, setValue}}>
      {props.children}
    </WorkingDocumentTypeContext.Provider>
  )
}