import {createContext, useEffect, useReducer} from 'react';

export const AuthContext: React.Context<any> = createContext({});

export const AuthContextProvider = (props: any) => {
  const [token, setToken] = useReducer((_: any, token: any) => token, [], () => {
    return localStorage.getItem('auth-token') ?? '';
  });

  useEffect(() => {
    localStorage.setItem('auth-token', token);
  }, [token])

  return (
    <AuthContext.Provider value={{token, setToken}}>
      {props.children}
    </AuthContext.Provider>
  )
}