import {useLocation, useNavigate} from "react-router-dom";
import {useContext, useEffect} from "react";
import {AuthContext} from "../services/auth-context";
import {instance} from "../services/axios-config";

function PageGuard() {
  const navigation = useNavigate();
  const {token, setToken} = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    if (location.state?.commingFromRedirect) {
      return;
    }

    if (token === '' && !(location.pathname ?? '').includes('/login')) {
      navigation('/login', {replace: true});
    }

    instance.defaults.headers['Authorization'] = `Bearer ${token}`;
    instance.get('/v1/user/validateToken')
      .then((_) => {
        if ((location.pathname ?? '').includes('/login')) {
          setToken('');
          navigation('/', {replace: true});
        }
      }).catch(_ => {
      if (!(location.pathname ?? '').includes('/login')) {
        setToken('');
        navigation('/login', {replace: true});
      }
    });
  }, [location, token])
}

export default PageGuard;