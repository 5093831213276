import {useQuery} from "react-query"; 
import { instance } from "../services/axios-config";
import Fund from "../entities/fund";

async function doLoadFunds(): Promise<Array<any>> { 
  const response = await instance.get('/v1/funds'); 
  return response.data.map((it: any) => Fund.fromMap(it));
}

export function DoLoadFunds() {
  return useQuery<Array<Fund>>(
   '/v1/funds',
   () => doLoadFunds(),
   {
     staleTime: 60 * 1000, 
   }
  );
}
