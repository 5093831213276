import {Descriptions, Modal, Table} from "antd";
import PropTypes from "prop-types";
import {AppointmentStock} from "../../../entities/appointment-stock";
import {formatDate} from "../../../services/date-format";
import {formatCurrency} from "../../../services/number-fomat";
import {DivergenceType} from "../../../entities/divergence-type";

export const ResumeModal = (props: any) => {
  const aptmt: AppointmentStock = props.appointment;

  const buildItems = () => {
    const items = [];
    if (aptmt.assignorName) {
      items.push({
        key: '1',
        label: 'Cedente',
        children: aptmt.assignorName,
        span: 3,
      });
    }

    if (aptmt.assignorId) {
      items.push({
        key: '2',
        label: 'Cedente Identificador',
        children: aptmt.assignorId,
        span: 3,
      });
    }

    if (aptmt.draweeId) {
      items.push({
        key: '3',
        label: 'Sacado',
        children: aptmt.draweeName,
        span: 3,
      });
    }

    if (aptmt.draweeName) {
      items.push({
        key: '4',
        label: 'Sacado Identificador',
        children: aptmt.draweeId,
        span: 3,
      });
    }

    if (aptmt.documentNumber) {
      items.push({
        key: '5',
        label: 'Documento',
        children: aptmt.documentNumber,
        span: 3,
      });
    }

    if (aptmt.yourNumber) {
      items.push({
        key: '6',
        label: 'Seu Número',
        children: aptmt.yourNumber,
        span: 3,
      });
    }

    if (aptmt.dueDate) {
      items.push({
        key: '7',
        label: 'Data Vencimento',
        children: formatDate(aptmt.dueDate),
        span: 3,
      });
    }

    if (aptmt.nominalValue) {
      items.push({
        key: '8',
        label: 'Valor Nominal',
        children: formatCurrency(Number(aptmt.nominalValue)),
        span: 3,
      });
    }

    if (aptmt.comment) {
      items.push({
        key: '9',
        label: 'Observação',
        children: aptmt.comment,
        span: 3,
      });
    }

    aptmt.customFields?.forEach((it, i) => {
      items.push({
        key: `customFields-${i}`,
        label: it.textLabel,
        children: it.textValue,
        span: 3,
      });
    });

    return items;
  }

  const divergenceTypes = (): DivergenceType[] => {
    if (!aptmt.divergenceTypeIds) return [];

    return aptmt.divergenceTypeIds
      .map(it => props.divergenceTypes.find((x: DivergenceType) => x.id === it))
      .filter(it => it);
  }

  return <Modal
    width={'40%'}
    key='ReceivableTypeItemModalSelector'
    open={props.isVisible}
    title={'Confirmação de auditoria'}
    destroyOnClose={true}
    centered={true}
    okText={'Confirmar'}
    closable={false}
    maskClosable={false}
    onCancel={props.onCancel}
    onOk={props.onConfirm}
  >
    {
      divergenceTypes().length === 0 &&
      buildItems().length === 0 &&
      (<p>Nenhuma divergência auditada.</p>)
    }
    <Descriptions layout='vertical' items={buildItems()}/>
    {
      divergenceTypes().length > 0
      &&
      (
        <Table
          columns={[{
            title: 'Tipos de divergência',
            dataIndex: 'data',
            key: 'data',
          }]}
          dataSource={divergenceTypes().map((it: DivergenceType) => ({
            key: it.id.toString(),
            data: it.label ?? it.code,
          }))}
          pagination={false}
          size='small'
        />
      )
    }

  </Modal>
}

ResumeModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  appointment: PropTypes.object.isRequired,
  divergenceTypes: PropTypes.array.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
