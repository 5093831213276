export default class SampleWallet {
  id: number;
  receivableTypeId: number;
  fund: string;
  documentCode: string;
  documentPaper: string;
  emitter: string;
  proposal: string;
  quantity: string;
  grossValue: string;
  applicationValue: string;
  criterion: string;
  dueDate: Date;
  applicationDate: Date;
  dataStatus: number;
  createdAt: Date;
  changedAt: Date;
  items?: SampleItem[];
  quarter: number;

  constructor(
    id: number,
    receivableTypeId: number,
    fund: string,
    documentCode: string,
    documentPaper: string,
    emitter: string,
    proposal: string,
    quantity: string,
    grossValue: string,
    applicationValue: string,
    criterion: string,
    dueDate: Date,
    applicationDate: Date,
    dataStatus: number,
    createdAt: Date,
    changedAt: Date,
    items: SampleItem[],
    quarter: number
  ) {
    this.id = id;
    this.receivableTypeId = receivableTypeId;
    this.fund = fund;
    this.documentCode = documentCode;
    this.documentPaper = documentPaper;
    this.emitter = emitter;
    this.proposal = proposal;
    this.quantity = quantity;
    this.grossValue = grossValue;
    this.applicationValue = applicationValue;
    this.criterion = criterion;
    this.dueDate = dueDate;
    this.applicationDate = applicationDate;
    this.dataStatus = dataStatus;
    this.createdAt = createdAt;
    this.changedAt = changedAt;
    this.items = items;
    this.quarter = quarter;
  }

  static fromJson(
    {
      id,
      receivableTypeId,
      fund,
      documentCode,
      documentPaper,
      emitter,
      proposal,
      quantity,
      grossValue,
      applicationValue,
      criterion,
      dueDate,
      applicationDate,
      dataStatus,
      createdAt,
      changedAt,
      items,
      quarter,
    }: any): SampleWallet {
    return new SampleWallet(
      id,
      receivableTypeId,
      fund,
      documentCode,
      documentPaper,
      emitter,
      proposal,
      quantity,
      grossValue,
      applicationValue,
      criterion,
      dueDate,
      applicationDate,
      dataStatus,
      createdAt,
      changedAt,
      items?.map(SampleItem.fromJson),
      quarter,
    )
  }
}

class SampleItem {
  code: string;
  status: number;


  constructor(code: string, status: number) {
    this.code = code;
    this.status = status;
  }

  static fromJson({code, status}: any): SampleItem {
    return new SampleItem(
      code,
      status
    );
  }
}