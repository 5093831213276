import React from "react";
import {InputNumber, InputNumberProps} from "antd";


const locale = "pt-BR";

const currencyFormatter = (value: any) => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: 'BRL',
  }).format(value);
};

const currencyParser = (val: any) => {
  try {
    if ((typeof val === "string" && !val.length)) {
      val = "0.0";
    }

    const v = val.replace(new RegExp(/\D+/g), '');
    let chars = v.split('');

    while (chars.length < 3) {
      chars = ['0', ...chars];
    }

    chars.splice(chars.length - 2, 0, ',')
    val = chars.join('');

    const group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, "");
    const decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, "");
    let reversedVal = val.replace(new RegExp("\\" + group, "g"), "");
    reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".");

    reversedVal = reversedVal.replace(/[^0-9.]/g, "");

    return Number.isNaN(reversedVal) ? 0 : reversedVal;
  } catch (error) {
    console.error(error);
  }
};

const handleOnChange = (e: any) => {
  if (e.target.value === 'R$ 0,00' && e.key === 'Backspace') e.preventDefault();
  if (/^[a-zA-z]{1}$/.test(e.key)) e.preventDefault();
}

export const CustomCurrencyInput = (props: InputNumberProps) => {
  return <InputNumber
    controls={false}
    precision={2}
    stringMode={false}
    min={0}
    {...props}
    formatter={currencyFormatter}
    parser={currencyParser}
    onKeyDown={handleOnChange}
  />
}
export default CustomCurrencyInput;