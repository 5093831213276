import {Table} from "antd";
import './sample-preview-list.css'
import {useLocation, useNavigate} from "react-router-dom";
import {formatDate} from "../../../services/date-format";
import ReceivableType from "../../../entities/receivable-type";
import React, {useContext} from "react";
import {DoLoadNextSampleWalletQuery} from "../hooks/do-load-next-sample-wallet";
import SampleWallet from "../../../entities/sample-wallet";
import {useSampleWalletFilterService} from "../../archive-wallet/service/use-sample-wallet-filter-service";
import {WorkingSystemConfigContext} from "../../../services/working-system-config-provider";

export const SamplePreviewList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const sample = location.state.sample;
  const receivableTypes: ReceivableType[] = location.state.receivableTypes;
  const filterService = useSampleWalletFilterService();
  const wscContext = useContext(WorkingSystemConfigContext);

  const sampleQuery = DoLoadNextSampleWalletQuery({
    filters: filterService.value,
    id: sample.id,
    quantity: 5,
    quarter: wscContext.value.quarter,
  });

  const columns = [
    {
      title: 'Tipo Recebível',
      dataIndex: 'receivableType',
    },
    {
      title: 'Fundo',
      dataIndex: 'fund',
    },
    {
      title: 'Emitente',
      dataIndex: 'emitter',
    },
    {
      title: 'Papel',
      dataIndex: 'documentPaper',
    },
    {
      title: 'Código',
      dataIndex: 'documentCode',
    },
    {
      title: 'Proposta',
      dataIndex: 'proposal',
    },
    {
      title: 'Data aplicação',
      dataIndex: 'applicationDate',
    },
  ];

  const tableData = () => {
    const samples = sampleQuery.data?.items ?? [];

    return samples.map((it: SampleWallet) => ({
      key: it.id,
      fund: it.fund,
      emitter: it.emitter,
      documentPaper: it.documentPaper,
      documentCode: it.documentCode,
      proposal: it.proposal,
      applicationDate: formatDate(it.applicationDate),
      receivableType: receivableTypes.find(x => x.id === it.receivableTypeId)?.code,
    }));
  }

  function onItemClick(record: any) {
    const samples = sampleQuery.data?.items ?? [];
    const s = samples.find((it: SampleWallet) => it.id === record.key)!;

    navigate(
      `/archive-wallet-page/item/${s.id}`,
      {
        state: {
          sample: s,
          receivableType: receivableTypes?.find(it => it.id === s.receivableTypeId),
          receivableTypes: receivableTypes,
        }
      }
    );
  }

  return (
    <Table
      className='table'
      loading={sampleQuery.isLoading}
      columns={columns}
      dataSource={tableData()}
      pagination={false}
      onRow={(record, _) => {
        return {
          onClick: (_) => onItemClick(record),
        };
      }}
    />
  );
}