export class DocumentType {
  id: number;
  code: string;
  label: string;
  createdAt: Date;
  changedAt: Date;
  dataStatus: number;

  constructor(id: number, code: string, label: string, createdAt: Date, changedAt: Date, dataStatus: number) {
    this.id = id;
    this.code = code;
    this.label = label;
    this.createdAt = createdAt;
    this.changedAt = changedAt;
    this.dataStatus = dataStatus;
  }

  static fromJson(
    {
      id,
      code,
      label,
      createdAt,
      changedAt,
      dataStatus
    }: any): DocumentType {
    return new DocumentType(
      id,
      code,
      label,
      createdAt,
      changedAt,
      dataStatus,
    );
  }
}