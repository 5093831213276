import React from 'react';
import './App.css';
import PageRoutes from "./routes/page-routes";
import {App as AntdApp, Layout} from "antd";
import {AuthContextProvider} from './services/auth-context';
import Navbar from "./components/navbar/navbar";
import {useLocation} from "react-router-dom";
import {AxiosInterceptor} from "./services/axios-interceptor";
import {UserContextProvider} from "./services/user-context";
import {WorkingDocumentTypeContextProvider} from "./services/working-document-type-context-provider";
import {WorkingSystemConfigContextProvider} from "./services/working-system-config-provider";

function App() {
  const location = useLocation();
  const isLoggedIn = !(location.pathname ?? '').includes("/login");

  return (
    <AntdApp>
      <AuthContextProvider>
        <UserContextProvider>
          <AxiosInterceptor>
            <WorkingSystemConfigContextProvider>
              <WorkingDocumentTypeContextProvider>
                <Layout style={{minHeight: '100vh'}}>
                  {!(location.pathname ?? '').includes("/login") ? <Navbar/> : null}
                  <Layout style={{marginLeft: isLoggedIn ? 80 : 0}}>
                    <PageRoutes/>
                  </Layout>
                </Layout>
              </WorkingDocumentTypeContextProvider>
            </WorkingSystemConfigContextProvider>
          </AxiosInterceptor>
        </UserContextProvider>
      </AuthContextProvider>
    </AntdApp>
  );
}

export default App;
