import {instance} from "../../../services/axios-config";
import User from "../../../entities/user";
import {App} from "antd";
import {useState} from "react";
import {useMutation} from "react-query";
import {AxiosError} from "axios";

const createUser = async (user: User) => {
  if (user.id) {
    await instance.put(`/v1/user/edit/${user.id}`, user);
  } else {
    await instance.post(`/v1/user/create`, user);
  }
}

export function DoCreateUser() {
  const {notification} = App.useApp();
  const [executing, setExecuting] = useState(false);

  const {mutate, isLoading, mutateAsync} = useMutation(createUser, {
      onMutate: () => {
        setExecuting(true);
      },
      onError: (error: AxiosError) => {
        setExecuting(false);
        const message = (error.response?.data as any)?.message ?? 'Erro interno';

        notification.error({
          duration: 7,
          message: message,
        });
      },
    }
  );

  return {mutate, mutateAsync, isLoading, executing};
}