import {Modal} from "antd";
import PropTypes from "prop-types";

export const ConfirmModal = (props: any) => {
  return <Modal
    open={props.isVisible}
    title={props.title}
    destroyOnClose={true}
    centered={true}
    okText={'Confirmar'}
    closable={false}
    maskClosable={false}
    onCancel={props.onCancel}
    onOk={props.onOk}
  >
    <p>{props.content}</p>
  </Modal>
}

ConfirmModal.prototype = {
  title: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
};