export default class SampleStock {
  id: number;
  receivableTypeId: number;
  fund: string;
  assignorName: string;
  assignorId: string;
  draweeName: string;
  draweeId: string;
  yourNumber: string;
  documentNumber: string;
  nominalValue: string;
  presentValue: string;
  criterion: string;
  dueDate: Date;
  acquisitionDate: Date;
  dataStatus: number;
  createdAt: Date;
  changedAt: Date;
  items?: SampleItem[];
  quarter: number;

  constructor(
    id: number,
    receivableTypeId: number,
    fund: string,
    assignorName: string,
    assignorId: string,
    draweeName: string,
    draweeId: string,
    yourNumber: string,
    documentNumber: string,
    nominalValue: string,
    presentValue: string,
    criterion: string,
    dueDate: Date,
    acquisitionDate: Date,
    dataStatus: number,
    createdAt: Date,
    changedAt: Date,
    items: SampleItem[],
    quarter: number
  ) {
    this.id = id;
    this.receivableTypeId = receivableTypeId;
    this.fund = fund;
    this.assignorName = assignorName;
    this.assignorId = assignorId;
    this.draweeName = draweeName;
    this.draweeId = draweeId;
    this.yourNumber = yourNumber;
    this.documentNumber = documentNumber;
    this.nominalValue = nominalValue;
    this.presentValue = presentValue;
    this.criterion = criterion;
    this.dueDate = dueDate;
    this.acquisitionDate = acquisitionDate;
    this.dataStatus = dataStatus;
    this.createdAt = createdAt;
    this.changedAt = changedAt;
    this.items = items;
    this.quarter = quarter;
  }

  static fromJson(
    {
      id,
      receivableTypeId,
      fund,
      assignorName,
      assignorId,
      draweeName,
      draweeId,
      yourNumber,
      documentNumber,
      nominalValue,
      presentValue,
      criterion,
      dueDate,
      acquisitionDate,
      dataStatus,
      createdAt,
      changedAt,
      items,
      quarter,
    }: any): SampleStock {
    return new SampleStock(
      id,
      receivableTypeId,
      fund,
      assignorName,
      assignorId,
      draweeName,
      draweeId,
      yourNumber,
      documentNumber,
      nominalValue,
      presentValue,
      criterion,
      dueDate,
      acquisitionDate,
      dataStatus,
      createdAt,
      changedAt,
      items?.map(SampleItem.fromJson),
      quarter
    )
  }
}

class SampleItem {
  code: string;
  status: number;


  constructor(code: string, status: number) {
    this.code = code;
    this.status = status;
  }

  static fromJson({code, status}: any): SampleItem {
    return new SampleItem(
      code,
      status
    );
  }
}