export default class Fund {
  id: number;
  name: string;
  document: string;


  constructor(id: number, name: string, document: string) {
    this.id = id;
    this.name = name;
    this.document = document;
  }

  static fromMap({id, name, document}: any) {
    return new Fund(id, name, document);
  }
}