export default class User {
  id?: number;
  name: string;
  email: string;
  username: string;
  password: string;
  role: string;
  dataStatus?: number;

  constructor(
    name: string,
    email: string,
    username: string,
    password: string,
    role: string
  ) {
    this.name = name;
    this.email = email;
    this.username = username;
    this.password = password;
    this.role = role;
  }

  static fromJson(
    {
      id,
      name,
      email,
      username,
      password,
      role,
      dataStatus,
    }: any): User {
    const u = new User(name, email, username, password, role);
    u.id = id;
    u.dataStatus = dataStatus;

    return u;
  }
}