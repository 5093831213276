import {Descriptions, Modal, Table} from "antd";
import PropTypes from "prop-types";
import {formatDate} from "../../../services/date-format";
import {formatCurrency} from "../../../services/number-fomat";
import {DivergenceType} from "../../../entities/divergence-type";
import {AppointmentWallet} from "../../../entities/appointment-wallet";

export const ResumeModal = (props: any) => {
  const aptmt: AppointmentWallet = props.appointment;

  const buildItems = () => {
    const items = [];

    if (aptmt.emitter) {
      items.push({
        key: '3',
        label: 'Emitente',
        children: aptmt.emitter,
        span: 3,
      });
    }

    if (aptmt.proposal) {
      items.push({
        key: '1',
        label: 'Número proposta',
        children: aptmt.proposal,
        span: 3,
      });
    }

    if (aptmt.quantity) {
      items.push({
        key: '2',
        label: 'Quantidade',
        children: aptmt.quantity,
        span: 3,
      });
    }

    if (aptmt.applicationValue) {
      items.push({
        key: '4',
        label: 'Valor aplicação',
        children: formatCurrency(Number(aptmt.applicationValue)),
        span: 3,
      });
    }

    if (aptmt.documentPaper) {
      items.push({
        key: '5',
        label: 'Papel',
        children: aptmt.documentPaper,
        span: 3,
      });
    }

    if (aptmt.documentCode) {
      items.push({
        key: '6',
        label: 'Código',
        children: aptmt.documentCode,
        span: 3,
      });
    }

    if (aptmt.grossValue) {
      items.push({
        key: '7',
        label: 'Valor bruto',
        children: formatCurrency(Number(aptmt.grossValue)),
        span: 3,
      });
    }

    if (aptmt.dueDate) {
      items.push({
        key: '8',
        label: 'Data vencimento',
        children: formatDate(aptmt.dueDate),
        span: 3,
      });
    }

    if (aptmt.comment) {
      items.push({
        key: '9',
        label: 'Observação',
        children: aptmt.comment,
        span: 3,
      });
    }

    aptmt.customFields?.forEach((it, i) => {
      items.push({
        key: `customFields-${i}`,
        label: it.textLabel,
        children: it.textValue,
        span: 3,
      });
    });

    return items;
  }

  const divergenceTypes = (): DivergenceType[] => {
    if (!aptmt.divergenceTypeIds) return [];

    return aptmt.divergenceTypeIds
      .map(it => props.divergenceTypes.find((x: DivergenceType) => x.id === it))
      .filter(it => it);
  }

  return <Modal
    width={'40%'}
    key='ReceivableTypeItemModalSelector'
    open={props.isVisible}
    title={'Confirmação de auditoria'}
    destroyOnClose={true}
    centered={true}
    okText={'Confirmar'}
    closable={false}
    maskClosable={false}
    onCancel={props.onCancel}
    onOk={props.onConfirm}
  >
    {
      divergenceTypes().length === 0 &&
      buildItems().length === 0 &&
      (<p>Nenhuma divergência auditada.</p>)
    }
    <Descriptions layout='vertical' items={buildItems()}/>
    {
      divergenceTypes().length > 0
      &&
      (
        <Table
          columns={[{
            title: 'Tipos de divergência',
            dataIndex: 'data',
            key: 'data',
          }]}
          dataSource={divergenceTypes().map((it: DivergenceType) => ({
            key: it.id.toString(),
            data: it.label ?? it.code,
          }))}
          pagination={false}
          size='small'
        />
      )
    }

  </Modal>
}

ResumeModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  appointment: PropTypes.object.isRequired,
  divergenceTypes: PropTypes.array.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
