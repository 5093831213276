import {theme} from "antd";
import React from "react";
import {Content} from "antd/es/layout/layout";

export function BaseContent(props: any) {
  const {
    token: {colorBgContainer, borderRadiusLG},
  } = theme.useToken();

  return (
    <Content style={{margin: '24px 16px 0'}}>
      <div
        style={{
          padding: 24,
          minHeight: 360,
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        {props.children}
      </div>
    </Content>
  );
}