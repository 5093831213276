import { Button, Col, DatePicker, Form, Input, Row, Select, Space, theme } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReceivableType from "../../../entities/receivable-type";
import dayjs from "dayjs";
import { DefaultOptionType } from "antd/es/select";
import Fund from "../../../entities/fund";

export const SampleWalletListFilter = (props: any) => {
  const [form] = useForm();
  const { token } = theme.useToken();
  const formStyle: React.CSSProperties = {
    maxWidth: 'none',
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
  };

  const receivableTypeOptions = () => {
    return props.receivableTypeList.map((it: ReceivableType) => {
      return {
        key: it.id,
        value: it.id,
        label: it.code,
      }
    });
  }

  const fundOptions = () => {
    return props.funds.map((it: Fund) => {
      return {
        key: it.id,
        value: it.id,
        label: it.name
      }
    })
  }

  const labelFilter = (input: any, option: any) : boolean => {
    return (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  };

  useEffect(() => {
    form.setFieldValue('fund', props.filters?.fund);
    form.setFieldValue('emitter', props.filters?.emitter);
    form.setFieldValue('documentPaper', props.filters?.documentPaper);
    form.setFieldValue('documentCode', props.filters?.documentCode);
    form.setFieldValue('proposal', props.filters?.proposal);
    form.setFieldValue('receivableType', props.filters?.receivableType);
    form.setFieldValue('hideStatusList', props.filters?.hideStatusList?.map((it: any) => it.toString()));
    form.setFieldValue('applicationDate', props.filters?.applicationDate?.map((it: any) => dayjs(it)));
  }, [props.filters]);

  return (
    <Form form={form} name="advanced_search" style={formStyle} onFinish={props.onSubmitFilter} layout='vertical'>
      <Row gutter={24}>
        <Col span={6}>
          <Form.Item name='fund' label='Fundo'>
            <Select
              allowClear
              mode="multiple"
              options={fundOptions()}
              filterOption={labelFilter}
            >
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='emitter' label='Emitente'>
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='documentPaper' label='Papel'>
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='documentCode' label='Código'>
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='proposal' label='Proposta'>
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='receivableType' label='Tipo Recebível'>
            <Select
              allowClear
              options={receivableTypeOptions()} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='hideStatusList' label='Ocultar já auditados'>
            <Select allowClear mode="multiple">
              <Select.Option value='1'>Cessão</Select.Option>
              <Select.Option value='2'>Recebível</Select.Option>
              <Select.Option value='3'>Doc. Origem</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name='applicationDate' label='Período Aquisição'>
            <DatePicker.RangePicker
              format="DD/MM/YYYY"
              placeholder={['Data inicial', 'Data final']}
              style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <div style={{ textAlign: 'right' }}>
        <Space size="small">
          <Button
            onClick={() => {
              form.resetFields();
            }}
          >
            Limpar
          </Button>
          <Button type="primary" htmlType="submit">
            Aplicar
          </Button>
        </Space>
      </div>
    </Form>
  );
}

SampleWalletListFilter.propTypes = {
  onSubmitFilter: PropTypes.func.isRequired,
  receivableTypeList: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  funds: PropTypes.array.isRequired,
};