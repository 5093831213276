import {instance} from "../../../services/axios-config";
import {useQuery} from "react-query";

interface DocParams {
  sampleId?: number
  documentTypeId?: number
}

const loadBase = async (params: DocParams) => {
  const response = await instance.post(`/v1/sample_wallet/files`, params);

  const files = [];
  for (const f of response.data) {
    const slice = f.documentName.split(".");
    const arraybuffer = await loadFile(f.uid);
    const blob = new Blob([arraybuffer], {type: 'application/pdf'});
    const url = URL.createObjectURL(blob);

    files.push({
      uid: f.uid,
      name: f.documentName,
      url: url,
      isPdf: slice[slice.length - 1].localeCompare('pdf', undefined, {sensitivity: 'accent'}) === 0,
    });
  }

  return files;
}

const loadFile = async (uid: string) => {
  const response = await instance.get(`/v1/sample_wallet/file/${uid}/show`, {
    responseType: 'arraybuffer'
  });

  return response.data;
}

export const DoLoadDocuments = (
  sampleId?: number,
  documentTypeId?: number
) => {
  return useQuery<any | null>(
    `/v1/sample_wallet/files/${sampleId}/${documentTypeId}`,
    () => loadBase({sampleId, documentTypeId}),
    {
      staleTime: 60 * 1000,
    }
  );
}